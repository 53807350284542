import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  const url = "https://www.hk01.com/18%E5%8D%80%E6%96%B0%E8%81%9E/669909/%E4%B8%8A%E4%B8%96%E7%B4%80-%E4%B8%83%E5%A7%8A%E5%A6%B9%E6%B3%B3%E6%A3%9A-%E9%87%8D%E7%8F%BE%E5%8C%97%E8%A7%92%E7%A2%BC%E9%A0%AD-%E4%B8%BB%E8%BE%A6%E6%96%B9-%E9%A6%96%E5%BA%A6%E5%98%97%E8%A9%A6%E6%B5%B7%E4%B8%8A%E5%89%B5%E4%BD%9C?utm_medium=Social&utm_source=fbpost&utm_campaign=realism&fbclid=IwAR37lHlNd6lXlA8zSuj6oHRFyPRskGGPwJgFE1EQHlugKov879_Zb_HjeFY";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
